// import 'bootstrap/dist/css/bootstrap.min.css'
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { IconContext } from "react-icons";
import "../styles/bootstrap.scss";
import Header from "./header";
import { FaTwitter } from "react-icons/fa";

const Layout = ({ children, siteTitle, _rawLogo }) => (
  <IconContext.Provider value={{ style: { verticalAlign: "text-top" } }}>
    <>
      <Header siteTitle={siteTitle} />
      <div>{children}</div>
      <Jumbotron className="text-center">
        <h2>Follow Rebase on Twitter</h2>
        <Button className="text-decoration-none" href="https://twitter.com/rebasefoundtn">
          <FaTwitter /> Follow Us
        </Button>
      </Jumbotron>
      <Jumbotron fluid className="mb-0">
        <Container fluid className="d-flex flex-column align-items-center">
          <div className="mb-2">&copy; 2019—{new Date().getFullYear()} Copyright Rebase</div>
        </Container>
      </Jumbotron>
    </>
  </IconContext.Provider>
);

export default Layout;
