import { Link } from "gatsby";
import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import BlogPostCard from "../components/BlogPostCard";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { getLink } from "../routes";
import Figure from "./Figure";

const buildSpotlightReference = (node, type) => {
  const { isReady, externalLink, title, _rawNetwork, _type } = node;

  if (isReady) {
    if (externalLink) {
      return (
        <a href={externalLink} target="blank">
          {title} <FaExternalLinkAlt />
        </a>
      );
    } else {
      return _rawNetwork.slug ? (
        <Link to={getLink(_rawNetwork.slug.current, _type, title)}>{title}</Link>
      ) : (
        title
      );
    }
  } else {
    return title;
  }
};

const serializers = {
  types: {
    mainImage: Figure,
    authorReference: ({ node }) => <b>{node.author.name}</b>,
    conceptReference: ({ node }) => <b>{node.concept.title}</b>,
    networkReference: ({ node }) =>
      node.network.ready ? (
        <Link to={`network/${node.network.slug.current}`}>{node.network.title}</Link>
      ) : (
        <b>{node.network.title}</b>
      ),
    postReference: ({ node }) =>
      node.post.publishedAt ? <BlogPostCard postNode={node.post} /> : <b>{node.post.title}</b>,
    sectorReference: ({ node }) => <b>{node.sector.title}</b>,
    roleReference: ({ node }) => buildSpotlightReference(node.role, "role"),
    systemParameterReference: ({ node }) => <b>{node.systemParameter.title}</b>,
    tokenReference: ({ node }) => (
      <>
        {node.token._rawImage && (
          <img
            src={imageUrlFor(buildImageObj(node.token._rawImage))
              .width(30)
              .url()}
            style={{ height: "1rem", marginRight: "4px", verticalAlign: "baseline" }}
          />
        )}
        {node.token.title}
      </>
    ),
    functionDescriberReference: ({ node }) => <b>{node.functionDescriber.title}</b>,
    industryReference: ({ node }) => <b>{node.industry.title}</b>,
    tutorialReference: ({ node }) => buildSpotlightReference(node.tutorial, "tutorial"),
    systemComponentReference: ({ node }) => {
      return buildSpotlightReference(node.systemComponent, "systemComponent");
    },
    specificationDocumentWalkthroughReference: ({ node }) => {
      return buildSpotlightReference(
        node.specificationDocumentWalkthrough,
        "specificationDocumentWalkthrough"
      );
    }
  }
};

export default serializers;
