import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import Card from "react-bootstrap/Card";
import styled from "styled-components";

const CardHeading = styled.h2`
  display: inline-block;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.5) 0);
`;

const CardSubtitle = styled.div`
  display: inline-block;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  padding: 5px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.5) 0);
`;

const CardText = styled.div`
  display: inline-block;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  padding: 5px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.5) 0);
`;

const BlogPostCard = props => {
  const { postNode } = props;
  return (
    <Link to={`post/${postNode.slug.current}`} className="text-decoration-none">
      <Card className="mb-3" style={{ overflow: "hidden" }}>
        <BackgroundImage fluid={postNode.mainImage.asset.fluid}>
          <Card.Body className="p-5">
            <CardHeading className="text-white mb-1">{postNode.title}</CardHeading>
            {postNode.subtitle && (
              <CardSubtitle className="text-white mb-1 lead">{postNode.subtitle}</CardSubtitle>
            )}
            <br />
            <CardText className="text-white">
              {postNode.publishedAt} · <span className="text-decoration-underline">Read now</span>{" "}
              <small>→</small>
            </CardText>
          </Card.Body>
        </BackgroundImage>
      </Card>
    </Link>
  );
};

export default BlogPostCard;
