import { slugify } from "../lib/helpers";
export const getLink = (networkSlugCurrent, documentType, documentTitle) => {
  const basePath = `/network/${networkSlugCurrent}/`;

  switch (documentType) {
    case "systemComponent":
      return `${basePath}/system-component/${slugify(documentTitle)}`;
    case "specificationDocumentWalkthrough":
      return `${basePath}/specification-document-walkthrough/${slugify(documentTitle)}`;
    case "role":
      return `${basePath}/role/${slugify(documentTitle)}`;
    case "tutorial":
      return `${basePath}/tutorial/${slugify(documentTitle)}`;
    default:
      return basePath;
  }
};
