import { getFluidGatsbyImage } from "gatsby-source-sanity";
import React from "react";
import clientConfig from "../../client-config";

const setBodyImageDimensions = node => {
  const { dimensions } = node.asset.metadata;

  if (dimensions.aspectRatio >= 1) {
    return { width: dimensions.width };
  } else {
    return { height: dimensions.height * 0.67 };
  }
};

export default ({ node }) => {
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth: node.asset.metadata.dimensions.width },
    clientConfig.sanity
  );

  return (
    <figure className="my-4 text-center">
      <img
        className="mb-2 img-fluid "
        style={setBodyImageDimensions(node)}
        src={node.asset.url}
        alt={node.alt}
      />
      <figcaption className="overline text-center">{node.caption}</figcaption>
    </figure>
  );
};
