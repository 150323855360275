import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const BrandColorsHorizontalDivider = ({
  brandColors = ["darkgray", "lightgray"],
  marginBottomNumber = 3
}) => {
  return (
    brandColors && (
      <Container className={`mb-${marginBottomNumber}`} fluid>
        <Row style={{ height: 5 }}>
          {brandColors
            .filter((color, i) => i <= 2)
            .map(brandColor => (
              <Col style={{ backgroundColor: brandColor }} />
            ))}
        </Row>
      </Container>
    )
  );
};

export default BrandColorsHorizontalDivider;
