import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaTwitter } from "react-icons/fa";

const Header = ({ onHideNav, onShowNav, showNav }) => (
  <Navbar bg="light" expand="lg">
    <Navbar.Brand>
      <Link to="/">
        <img src={require("../images/logo.png")} style={{ width: "100px" }} alt={`Rebase logo`} />
      </Link>
    </Navbar.Brand>

    <Navbar.Toggle className="overline px-2 py-1">Menu</Navbar.Toggle>

    <Navbar.Collapse>
      <Nav>
        <div className="nav-item">
          <Link className="nav nav-link text-decoration-none overline" to="/spotlight">
            Spotlight
          </Link>
        </div>
        <div className="nav-item">
          <Link className="nav nav-link text-decoration-none overline" to="/blog">
            Research Blog
          </Link>
        </div>
      </Nav>
      <div className="nav-item ml-auto">
        <a href="https://1inch.exchange/#/r/0xE6E98e52Cd024Af8A41032fE7363DD7f4A8dFDBf/ETH/RUNE" target="_blank" rel="noreferrer noopener" className="mr-3">
          <Button style={{ background: 'linear-gradient(90deg, #00CCFF 0%, #33FF99 99.99%)', borderColor: '#005266', color: '#005266' }}>Buy $RUNE</Button>
        </a>
        <Button
          className="text-decoration-none ml-auto"
          variant="outline-dark"
          href="https://twitter.com/rebasefoundtn"
        >
          <FaTwitter /> Follow Us on Twitter
        </Button>
      </div>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
